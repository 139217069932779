<template>
  <div class="indemnite-kilometrique-create page-layout">
    <PageHeader
      title="Créer indemnite kilometrique"
      :has-back="true"
      @back="goBack"
    />
    <!-- La barre d'action -->

    <md-card class="meep-form">
      <md-card-content>
        <div class="meep-form-column meep-form-single-column">
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('type_vehicule') }">
              <label>Type vehicule*</label>
              <md-select v-model="form.type_vehicule">
                <md-option value="auto">Auto</md-option>
                <md-option value="moto">Moto</md-option>
                <md-option value="cyclo">Cycle</md-option>
              </md-select>
              <span class="md-error" v-show="errors.has('type_vehicule')">
                {{ errors.first("type_vehicule") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('km_min') }">
              <label>Km min *</label>
              <md-input
                v-model="form.km_min"
                v-validate="'required|decimal'"
                name="km_min"
                type="text"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('km_min')">
                {{ errors.first("km_min") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('km_max') }">
              <label>Km max *</label>
              <md-input
                v-model="form.km_max"
                v-validate="'decimal'"
                name="km_max"
                type="text"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('km_max')">
                {{ errors.first("km_max") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('cv_min') }">
              <label>CV min*</label>
              <md-input
                v-model="form.cv_min"
                v-validate="'required|numeric'"
                name="cv_min"
                type="text"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('cv_min')">
                {{ errors.first("cv_min") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('cv_max') }">
              <label>CV max *</label>
              <md-input
                v-model="form.cv_max"
                v-validate="'numeric'"
                name="cv_max"
                type="text"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('cv_max')">
                {{ errors.first("cv_max") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('coef') }">
              <label>Coef *</label>
              <md-input
                v-model="form.coef"
                v-validate="'required|decimal'"
                name="coef"
                type="text"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('coef')">
                {{ errors.first("coef") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field
              :class="{ 'md-invalid': errors.has('montant_additionnel') }"
            >
              <label>Montant addtionnel *</label>
              <md-input
                v-model="form.montant_additionnel"
                v-validate="'required|decimal'"
                name="montant_additionnel"
                type="text"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('montant_additionnel')">
                {{ errors.first("montant_additionnel") }}
              </span>
            </md-field>
          </div>

          <div class="md-layout md-alignment-center-right">
            <md-button class="md-raised md-primary" @click.native="createKilo">
              Créer indemnité kilometrique
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import calculatorsModel from "../../../../model/calculators";
import PageHeader from "@/components/PageHeader";

export default {
  components: { PageHeader },
  data() {
    return {
      isDebug: false,
      form: {
        type_vehicule: "",
        km_min: "",
        km_max: "",
        cv_min: "",
        cv_max: "",
        coef: "",
        montant_additionnel: "",
      },
    };
  },
  methods: {
    goBack() {
      window.history.back();
    },

    createKilo() {
      this.$validator.validateAll().then(result => {
        if (result) {
          calculatorsModel
            .createKilo(this.form)
            .then(() => {
              this.$toasted.global.AppSucces({
                message: "L'indemnite  de kilometrique a bien été enregistré",
              });

              this.$router.push(
                "/admin/calculators/indemnite-kilometrique/list"
              );
            })
            .catch(err => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.indemnite-kilometrique-create {
  .debug-log {
    max-height: 360px;
    overflow-y: scroll;
  }

  .md-card {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
